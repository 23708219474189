.team {
  background: linear-gradient(181deg, #f7971e, #ffc200, #fff 90%) fixed
    no-repeat;
}
.team .parallax-container {
  height: 100%;
  position: relative;
}
.team .parallax-images {
  position: absolute;
  width: 100%;
}
.team .parallax-images {
  background: url('/assets/img/background/svg/icons/img-illustrations.svg')
    repeat;
  background-size: cover;
  height: 100%;
}
@media screen and (min-width: 768px) {
  .team .parallax-images {
    background-size: 100%;
    height: 200%;
  }
}
.team .parallax-images {
  background-repeat: repeat;
  background-size: 100rem;
  height: 100%;
  z-index: -1;
}
@media screen and (min-width: 768px) {
  .team .parallax-images {
    background-position: left;
    background-size: 120rem;
  }
}
.team .body .row {
  margin: 0;
  padding: 0;
}
.team .body .card-columns {
  column-count: 1;
}
@media screen and (min-width: 768px) {
  .team .body .card-columns {
    column-count: 2;
  }
}
@media screen and (min-width: 992px) {
  .team .body .card-columns {
    column-count: 3;
  }
}
.team .body .card {
  border: 0;
  box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  max-width: 29.3rem;
}
.team .body .card .member {
  box-shadow: 0 5px 3px 0 rgba(0, 0, 0, 0.1);
  height: auto;
  width: 100%;
}
.team .body .card .card-content {
  margin-left: 1.5rem;
  margin-right: 1rem;
}
.team .body .card h1 {
  color: #212121;
  font-family: 'DINPro-Bold', 'Helvetica Neue', 'Arial', sans-serif;
  font-size: 2rem;
  text-align: left;
  margin-right: 2.1rem;
  margin-top: 2.6rem;
  text-transform: uppercase;
}
.team .body .card h2 {
  color: #4a4a4a;
  font-family: 'DINPro-Bold', 'Helvetica Neue', 'Arial', sans-serif;
  font-size: 1.8rem;
  text-align: left;
  text-transform: uppercase;
}
.team .body .card h3 {
  color: #9b9b9b;
  font-family: 'DINPro-Light', 'Helvetica Neue', 'Arial', sans-serif;
  font-size: 1.6rem;
  text-align: left;
  line-height: 1.25;
}
.team .body .card p {
  color: #212121;
  font-family: 'DINPro-Regular', 'Helvetica Neue', 'Arial', sans-serif;
  font-size: 1.8rem;
  text-align: left;
  line-height: normal;
  margin-bottom: 2rem;
}
.team .body .card .logo {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}
.team .body .card .logo img {
  backface-visibility: hidden;
  margin-right: 1.5rem;
}
.team .body .card .logo img:hover {
  animation: pop 0.4s 2 cubic-bezier(0.08, 0.63, 0.18, 0.39);
}
.team .body .card .button-row {
  margin-bottom: 1.4rem;
}
.team .body .card button {
  color: #4a4a4a;
  font-family: 'DINPro-Regular', 'Helvetica Neue', 'Arial', sans-serif;
  font-size: 1.2rem;
  text-align: center;
  background-color: #fff;
  border: solid 1px #ffd63f;
  border-radius: 100px;
  height: 2.5rem;
  margin-bottom: 0.5rem;
  margin-right: 0.25rem;
  margin-top: 0.5rem;
  padding-left: 1.35rem;
  padding-right: 1.35rem;
}
@media screen and (min-width: 768px) {
  .team .body .card {
    max-width: 31rem;
  }
  .team .body .card .card-content {
    margin-left: 1.9rem;
    margin-right: 2rem;
  }
}
@media screen and (min-width: 992px) {
  .team .body .card {
    max-width: 34.4rem;
  }
  .team .body .card .card-content {
    margin-left: 2rem;
    margin-right: 1.6rem;
  }
}
.team header .banner {
  padding-bottom: 3rem;
  padding-top: 4rem;
  width: 14.1rem;
}
.team header .content {
  margin-bottom: 2.5rem;
}
.team header h1 {
  font-size: 2rem;
  letter-spacing: normal;
  padding-bottom: 6.5rem;
  margin-top: 0;
}
.team header .arrow {
  display: block;
  margin: 0.02rem auto;
  margin-bottom: 6.8rem;
}
@media screen and (min-width: 768px) {
  .team header .banner {
    padding-bottom: 5.1rem;
    padding-top: 6.8rem;
    width: 21rem;
  }
  .team header .content {
    margin-bottom: 4.5rem;
  }
  .team header h1 {
    font-size: 3rem;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .team header .btn {
    font-size: 1.8rem;
    padding: 1.7rem;
    width: 31.8rem;
  }
}
@media screen and (min-width: 992px) {
  .team header .banner {
    padding-bottom: 8.2rem;
    padding-top: 11.1rem;
    width: 30rem;
  }
  .team header .content {
    margin-bottom: 6.9rem;
  }
  .team header h1 {
    font-size: 5rem;
  }
  .team header .btn {
    font-size: 1.8rem;
    padding: 2.8rem;
    width: 34.9rem;
    height: 8rem;
  }
  .team header .arrow {
    height: 5.8rem;
    margin-bottom: 5.6rem;
    margin-top: 1.5rem;
  }
}
